<template>
    <div class="row mt-2">
		<label class="col-sm-4 col-form-label race_code">{{ $t('horse.race') }}</label>
		<div class="col-sm-8">
        	<e-select
				id="race_code"
				v-model="selected_race"
				track-by="race_code"
				label="race_display"
				:placeholder="$t('horse.race_cheval')"
				:options="horse_races"
				:searchable="true"
				:allow-empty="false"
				:loading="is_working"
				:show-labels="false"
				:disabled="!isEditable"
				@input="onChange"
			/>		
        </div>	
    </div>
</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [Config, HorseMixin],
        props: [
            'horse_race_code',
            'editable'
        ],
        data() {
            return {
                horse_races: [],
                selected_race: null,
				is_working: true
            }
        },
        computed: {
            isEditable () {
                if(this.editable !== undefined) {
                    return this.editable
                }
                return false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_races = await this.getRaces()
				this.checkSelect()
				this.is_working = false
            },
            checkSelect() {
                this.selected_race = this.horse_races.find(hr => hr.race_code == this.horse_race_code)
            },
			onChange(obj) {
				this.$emit('change', obj.race_code)
			}
        },
        watch:{
            horse_race_code() {
                this.checkSelect()
            }
        }
    }
</script>
